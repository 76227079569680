<template>
  <div class="info"   :style="'left:' + marker.left + ';top:' + marker.top + ';'">
    <img @mouseover="$emit('showPopup', marker)"  @click="$emit('showModal', marker)" class="marker" :src="'/images/' + marker.icon + '.svg'" alt="marker">
    <img src="/images/shadow.svg" class="shadow">
<!--    <transition name="fade">-->
<!--      <div v-show="showPopup" @click="$emit('showModal', marker)" @mouseout="showPopup=false" class="popup">-->
<!--        <img class="circle-image" :src="marker.image" alt="sunrise">-->
<!--        <div class="popuptext">{{ marker.name }}</div>-->
<!--      </div>-->
<!--    </transition>-->

  </div>
</template>

<script>
export default {
  name: "MarkerComponent",
  props: ['marker'],
  data () {
    return {
      showPopup:false,
    }
  },
  computed: {
    markerImg() {
      if(this.marker.icon == 'crab') {
        return '/images/crab.svg'
      }
      if(this.marker.icon == 'play') {
        return '/images/play.svg'
      }
      if(this.marker.icon == 'shrimp') {
        return '/images/shrimp.svg'
      }
      if(this.marker.icon == 'information') {
        return '/images/information.svg'
      }
      return '/images/marker.svg'

    }
  }
}
</script>

<style scoped>

</style>