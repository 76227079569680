<template>
  <div class="info"   :style="'left:' + marker.left + ';top:' + marker.top + ';'">
    <transition name="fade">
      <div v-show="marker.active" @click="$emit('showModal', marker)" @mouseout="$emit('hidePopup')" class="popup">
        <img class="circle-image" :src="marker.image" alt="sunrise">
        <div class="popuptext">{{ marker.name }}</div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "PopupComponent",
  props: ['marker'],
  computed: {
    markerImg() {
      if(this.marker.icon == 'crab') {
        return '/images/crab.svg'
      }
      if(this.marker.icon == 'play') {
        return '/images/play.svg'
      }
      if(this.marker.icon == 'shrimp') {
        return '/images/shrimp.svg'
      }
      return '/images/marker.svg'

    }
  }
}
</script>

<style scoped>

</style>