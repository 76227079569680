<template>
  <div class="video-modal">
    <video ref="video" class="video" autoplay>
      <source :src="'/video/' + marker.video" type="video/mp4">
      Your browser does not support the video tag.
    </video>
    <span class="video-icon video-fullscreen" @click="fullscreen"><i class="icon icon-enlarge"></i></span>
    <span class="video-icon video-cross" @click="$emit('close')"><i class="icon icon-cross"></i></span>
    <div class="titlebox">
      <h2>{{marker.name}}</h2>
    </div>
  </div>
</template>

<script>




export default {
  name: "VideoModal",
  props: ['marker'],
  methods: {
    fullscreen() {
      this.$refs.video.requestFullscreen();

    }
  }
}


</script>
