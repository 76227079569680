<template>
  <div class="text-modal">
    <div class="title-bar">
      <h2>{{marker.name}}</h2>
      <span @click="$emit('close')"><i class="icon icon-cross cross"></i></span>
    </div>


      <div class="modal-container">
      <div class="images">
        <div class="text-video-wrapper" v-if="marker.video && video_visible">
          <video class="video" loop ref="video" autoplay>
            <source :src="'/video/' + marker.video" type="video/mp4">
            Your browser does not support the video tag.
          </video>
          <span class="video-icon video-fullscreen" @click="fullscreen"><i class="icon icon-enlarge"></i></span>
        </div>

        <div class="lead-image-wrapper" v-if="(!marker.video || !video_visible) && marker.otherImages">
        <transition-group name="fade">
          <img
              v-for="(image, index) in limitedImages"
              v-show="visible(index)"
              class="fader"
              :key="image + index"
              :src="image" alt="">
        </transition-group>
        </div>
        <div
            v-if="marker.otherImages"
            class="images-row">
          <div class="video-toggle-wrapper" v-if="marker.video && marker.video_thumbnail">
            <img
                :src="marker.video_thumbnail"
                alt=""
            >
            <span class="play-button" @click="showVideo"><i class="icon icon-play"></i></span>
          </div>
          <img
              v-for="(image, index) in limitedImages"
              @click="showImage(index)"
              :key="index"
              :src="image">
        </div>
      </div>
        <div class="text" :class="{'colofon': marker.colofon}">
          <div v-html="marker.text"></div>
          <ul class="marker-links" v-if="marker.links">
            <li
                v-for="(link, index) in marker.links"
                :key="index"
            >
              <i class="icon icon-link"></i>
              <a target="_blank" :href="link.url"> {{link.title}}</a>
            </li>
          </ul>
        </div>

    </div>

  </div>
</template>

<script>




export default {
  name: "TextModal",
  props: ['marker'],
  data () {
    return {
      image_index:0,
      video_visible:true
    }
  },
  computed: {
    limitedImages() {
      return this.marker.otherImages.slice( 0, 6);
    },
  },
  methods: {
    showVideo() {
      this.video_visible=true;
    },
    showImage(index) {
      this.video_visible=false;
      this.image_index=index;
    },
    visible(index) {
      return this.image_index == index;
    },
    fullscreen() {
      this.$refs.video.requestFullscreen();

    }
  }

}


</script>
