<template>
  <div id="app">
    <div id="mainwrapper">
    <image-container/>
    <bottom/>
    </div>
    <audio hidden autoplay>
      <source src="/Crisp_Ocean_Waves-Mike_Koenig-1486046376.mp3" type="audio/mpeg">
      <source src="/Crisp_Ocean_Waves-Mike_Koenig-1486046376.wav" type="audio/wav">
    </audio>
  </div>
</template>

<script>
import ImageContainer from "@/components/ImageContainer";
import Bottom from "@/components/Bottom";


export default {
  name: 'App',
  components: {
    Bottom,
    ImageContainer,
  }
}
</script>

