<template>

    <div id="imagecontainer">
      <div class="slider-wrapper">
      <i @click="plus" class="icon icon-plus slider-plus"></i>
      <vue-slider
          direction="btt"
          v-model="value"
          tooltip="none"
          @change="change"
      />
        <i @click="min" class="icon icon-min slider-min"></i>
      </div>
      <div id="imagewrapper" :style="'position:relative'">

        <div id="mapcontainer" :style="'position:relative; transform-origin: left top; transform: scale(' + scale +');'">
          <img id="img" :src="src" alt="">
          <div class="markerbox">
            <marker-component
                v-for="(marker, index) in markers"
                :key="index"
                :marker="marker"
                @showModal="showModal"
                @showPopup="showPopup"
                :style="'transform-origin: center center; transform: translate(-50%, -50%) scale(' + 1/scale +');'"
            >
            </marker-component>
          </div>
          <div class="popupbox">
            <popup-component
                v-for="(marker, index) in markers"
                :key="index"
                :marker="marker"
                @hidePopup = "hidePopup"
                @showModal="showModal"
                :style="'transform-origin: center center; transform: translate(-50%, -50%) scale(' + 1/scale +');'"
            >
            </popup-component>
          </div>



        </div>


      </div>
    </div>


</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import MarkerComponent from "@/components/MarkerComponent";
import {MARKERS, LABELS} from "@/markers";
import TextModal from "@/components/TextModal";
import VideoModal from "@/components/VideoModal";
import PopupComponent from "@/components/PopupComponent";

const FACTOR = 50;

export default {
  components: {
    VueSlider,
    MarkerComponent,
    PopupComponent
  },
  data () {
    return {
      value: 0,
      oldvalue:0,
      markers: [],
      labels: LABELS,
      src:'/Levende-Noordzee.png'
    }
  },
  watch: {
    value(value) {
      if(value >= 50) {
        this.src='/Levende-Noordzee2.jpg'
      } else {
        this.src='/Levende-Noordzee.png'
      }

    }
  },
  computed: {
    scale() {
      return (this.value / FACTOR) + 1;
    },
    scrollLeft() {
      let width = document.getElementById('imagecontainer').offsetWidth;
      let difference = (this.value - this.oldvalue);
     return  difference / FACTOR * width * 0.5;
    },
    scrollTop() {
      let height = document.getElementById('imagecontainer').offsetHeight;
      let difference = (this.value - this.oldvalue);
      return  difference / FACTOR * height * 0.5;
    }
  },
  methods : {
    showModal(marker) {
      let modal = marker.modal_type == 'video' ? VideoModal : TextModal;
      this.$modal.show(
          modal,
          {
            marker
          },
          {
            width: window.innerWidth < 1020 ? '90%' : 900,
            height:'auto',
            scrollable: true
          }
      )
    },
    showPopup(marker) {
      marker.active=true;
    },
    hidePopup() {
      this.markers.forEach((marker) => marker.active=false);
    },
    change() {
      if(this.oldvalue < this.value) {
        document.getElementById('imagecontainer').scrollLeft += this.scrollLeft;
        document.getElementById('imagecontainer').scrollTop += this.scrollTop;
      }
      if(this.oldvalue > this.value) {
        document.getElementById('imagecontainer').scrollLeft += this.scrollLeft;
        document.getElementById('imagecontainer').scrollTop += this.scrollTop;
      }
      this.oldvalue = this.value;
    },
    plus() {
      if(this.value < 90) {
        this.value += 10;
      } else {
        this.value = 100;
      }
      this.change();
    },
    min() {
      if(this.value > 10) {
        this.value -= 10;
      } else {
        this.value = 0;
      }
      this.change();
    }
  },
  mounted() {
    MARKERS.forEach((marker) => marker.active=false)
    this.markers = MARKERS;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
