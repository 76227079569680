<template>

  <div id="bottom">
    <h1>De levende Noordzee</h1>
    <h2>duik mee onder water</h2>
  </div>
</template>

<script>
export default {
  name: "Bottom"
}
</script>
